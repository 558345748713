import { AxiosError } from 'axios';
import { createEffect, createEvent, sample } from 'effector';
import { decamelizeKeys } from 'humps';

import { endpoints } from '@vkph/common/endpoints';
import {
  DictPaginated,
  PaginatedListResults,
  PaginatedNextResults,
  PaginationParams,
} from '@vkph/common/types/api';
import {
  isBlogPost,
  isMicropost,
  isNewsPost,
  isPaginatedListResults,
  isPaginatedNextResults,
} from '@vkph/common/types/guards';
import {
  PermissionsV2Enum,
  PostBasicModel,
  PostModel,
  PostStatuses,
  PostTextModel,
  TimelineBlogEntryContentModel,
  TimelineContent,
  TimelineMicropostContentModel,
  TimelineModel,
  TimelineNewsContentModel,
  TimelineRecordModel,
  TimelineSettingsAllModel,
  TimelineTypes,
} from '@vkph/common/types/models';
import {
  AbstractStorageConfiguration,
  abstractStorageFactory,
  buildEndpointWithQueryParams,
} from '@vkph/common/utils';

import { isTimelineRecordModelSomeOfTypes } from '../../types/guards/timeline';
import {
  ApprovePostParams,
  createPost,
  CreatePostParams,
  deletePost,
  DeletePostParams,
  FetchPostParams,
  getSinglePostEndpoint,
  resetPostsSettings,
  switchFavoritePost,
  SwitchFavoritePostParams,
  switchPinPost,
  SwitchPinPostParams,
  UpdateCommentsCountEventParams,
  updatePost,
  UpdatePostParams,
  updatePostsSettings,
} from './api';

type SwitchFavoritePostEvent = {
  updatedPost: PostBasicModel;
};

const decamelizePostPermissions = <T extends object = PostBasicModel>(post: T): T => {
  return 'permissionsV2' in post
    ? {
        ...post,
        permissionsV2: decamelizeKeys(post.permissionsV2) as Record<PermissionsV2Enum, boolean>,
      }
    : post;
};

const updatePostsFavoriteEvent = createEvent<SwitchFavoritePostEvent>();

export const createPostEffect = createEffect<CreatePostParams, PostBasicModel, AxiosError>(async (params) => {
  const { data: post } = await createPost<PostBasicModel>(params);

  return decamelizePostPermissions(post);
});

export const updatePostEffect = createEffect<UpdatePostParams, PostBasicModel, AxiosError>(async (params) => {
  const { data: post } = await updatePost<UpdatePostParams, PostBasicModel>(params);

  return decamelizePostPermissions(post);
});

export const deletePostEffect = createEffect<DeletePostParams, unknown, AxiosError>((params) =>
  deletePost(params),
);

export const switchPinPostEffect = createEffect<SwitchPinPostParams, unknown, AxiosError>((params) =>
  switchPinPost(params),
);

// TODO: Вытащить News в отдельную фабрику?
export const getSinglePostStorage = <T extends PostModel>() => {
  const storage = abstractStorageFactory<T, T, null, FetchPostParams>({
    endpointBuilder: getSinglePostEndpoint,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
    // TODO: решить, должны ли приходить пермишены для новостей с бекенда
    dataMapper: (post) => (isNewsPost(post) ? { ...post, permissions: [] } : post),
  });
  const { store } = storage;

  const switchFavoritePostEffect = createEffect<SwitchFavoritePostParams, unknown, AxiosError>((params) =>
    switchFavoritePost(params),
  );

  const updateCommentsCountEvent = createEvent<number>();

  store
    .on(updatePostEffect.done, () => {
      storage.refetchWithLastParams();
    })
    .on(updateCommentsCountEvent, (state, commentsCount) => {
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              commentsCount,
            }
          : state.data,
      };
    })
    .on(switchFavoritePostEffect.done, (state, { params: { favorite } }) => {
      if (state.data && (isBlogPost(state.data) || isMicropost(state.data))) {
        updatePostsFavoriteEvent({ updatedPost: { ...state.data, favorite } });
      }

      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              favorite,
            }
          : state.data,
      };
    });

  return {
    storage,
    switchFavoritePostEffect,
    updateCommentsCountEvent,
  };
};

export enum TagsTypes {
  Story = 'story',
}

export type FetchPostsParams = {
  userId?: string;
  pinnedOnly?: boolean;
  myEntries?: boolean;
  flag?: PostStatuses;
  user?: string;
  tags?: TagsTypes;
} & Partial<PaginationParams>;

export type PostTimelineModel = PostTextModel | TimelineRecordModel;

type GetPostsResponse =
  | PaginatedListResults<PostTextModel>
  | DictPaginated<PostTextModel>
  | PaginatedNextResults<TimelineModel>;
type GetPostsStorage = AbstractStorageConfiguration<
  GetPostsResponse,
  PostTimelineModel[],
  PostTimelineModel[],
  FetchPostsParams
>;

export interface GetPostsStorageParams extends Pick<GetPostsStorage, 'shouldAppendData' | 'dataMapper'> {
  baseEndpoint: string;
  baseParams?: FetchPostsParams;
  shouldAddRemovePostsOnFavoriteChange?: boolean;
}

// TODO: Привести пагинацию к одному виду, к виду DictPaginated
export const getPostsStorage = (storageParams: GetPostsStorageParams) => {
  const {
    baseEndpoint,
    baseParams,
    shouldAppendData = false,
    shouldAddRemovePostsOnFavoriteChange = false,
  } = storageParams;

  const storage = abstractStorageFactory<
    GetPostsResponse,
    PostTimelineModel[],
    PostTimelineModel[],
    FetchPostsParams
  >({
    defaultValue: [],
    shouldAppendData,
    cancelPendingRequestOnFetch: true,
    endpointBuilder: (params) => buildEndpointWithQueryParams(baseEndpoint, { ...baseParams, ...params }),
    paginationInfoRetriever: (paginatedData) => {
      if (isPaginatedNextResults(paginatedData)) {
        const { meta } = paginatedData;

        return { next: meta.next };
      }

      return {
        count: paginatedData.meta?.objectsTotal,
        pageSize: paginatedData.meta?.objectsCount,
        page: paginatedData.meta?.pageNumber,
      };
    },
    dataMapper: (data) => {
      const decamelizePermissionV2 = (items: PostTimelineModel[]): PostTimelineModel[] => {
        return items.map((item) => decamelizePostPermissions<PostTimelineModel>(item));
      };

      if (isPaginatedNextResults(data)) {
        const items: PostTimelineModel[] = [];
        const availableTimelineRecordTypes: TimelineTypes[] = [
          TimelineTypes.ThanksCreated,
          TimelineTypes.BadgeManualCreated,
          TimelineTypes.BadgeAutoCreated,
          TimelineTypes.SkillApproved,
          TimelineTypes.CompetenceApproved,
          TimelineTypes.UserStructureUpdated,
          TimelineTypes.CommentCreatedNews,
          TimelineTypes.CommentCreatedMicropost,
          TimelineTypes.CommentCreatedEntry,
          TimelineTypes.CommentCreatedThanks,
          TimelineTypes.CommentCreatedUserBadge,
          TimelineTypes.CommentCreatedFileVersion,
          TimelineTypes.CommentCreatedSitectorPage,
          TimelineTypes.CommentCreatedAlbumImage,
          TimelineTypes.CommentCreatedTask,
          TimelineTypes.CommentCreatedRecord,
          TimelineTypes.CommentReplyNews,
          TimelineTypes.CommentReplyMicropost,
          TimelineTypes.CommentReplyEntry,
          TimelineTypes.CommentReplyThanks,
          TimelineTypes.CommentReplyUserBadge,
          TimelineTypes.CommentReplyFileVersion,
          TimelineTypes.CommentReplySitectorPage,
          TimelineTypes.CommentReplyAlbumImage,
          TimelineTypes.CommentReplyTask,
          TimelineTypes.CommentReplyRecord,
        ];

        data.results.forEach(({ recordsGroup = [] }) => {
          recordsGroup.forEach((record) => {
            const { id, actor, target, createdAt, publishedAt } = record;
            const baseRecord = { id, actor, target, createdAt, publishedAt };

            if (
              isTimelineRecordModelSomeOfTypes<TimelineBlogEntryContentModel>(record, [
                TimelineTypes.BlogEntryCreated,
              ])
            ) {
              items.push(record.content.entry);
            }

            if (
              isTimelineRecordModelSomeOfTypes<TimelineMicropostContentModel>(record, [
                TimelineTypes.MicropostCreated,
              ])
            ) {
              items.push(record.content.micropost);
            }

            if (
              isTimelineRecordModelSomeOfTypes<TimelineNewsContentModel>(record, [TimelineTypes.NewsPinned])
            ) {
              items.push(record.content.news);
            }

            if (isTimelineRecordModelSomeOfTypes<TimelineContent>(record, availableTimelineRecordTypes)) {
              items.push({ type: record.type, ...baseRecord, content: record.content });
            }
          });
        });

        return decamelizePermissionV2(items);
      }

      if (isPaginatedListResults(data)) {
        return decamelizePermissionV2(data.results);
      }

      return decamelizePermissionV2(data.items);
    },
  });

  const { store } = storage;

  const resetAndRefetchEvent = createEvent();

  const changePostStatusEffect = createEffect<ApprovePostParams, PostTimelineModel, AxiosError>((params) =>
    updatePost<ApprovePostParams, PostTimelineModel>(params).then(({ data }) => data),
  );

  const switchFavoritePostEffect = createEffect<SwitchFavoritePostParams, unknown, AxiosError>((params) =>
    switchFavoritePost(params),
  );

  const updateCommentsCountEvent = createEvent<UpdateCommentsCountEventParams>();

  store
    .on(deletePostEffect.done, (state, { params: { postId } }) => ({
      ...state,
      data: state.data.filter(({ id }) => id !== postId),
    }))
    .on(createPostEffect.done, (state, { result: post }) => {
      const { pinnedOnly, flag } = baseParams || {};
      const { flag: postFlag } = post;

      // TODO:: Убрать все проверки ниже (B2BCORE-7241).
      const isPinnedPostsStorage = Boolean(pinnedOnly);
      const isPremoderationPostsStorage = flag === PostStatuses.AwaitingApprove;
      const isPublishedPost = postFlag === PostStatuses.FlagPublished;

      if (isPinnedPostsStorage || (isPremoderationPostsStorage && isPublishedPost)) {
        return state;
      }

      return { ...state, data: [post, ...state.data] };
    })
    .on(changePostStatusEffect.done, (state, { result }) => ({
      ...state,
      data: state.data.map((post) => {
        return post.id === result.id ? result : post;
      }),
    }))
    .on(updatePostEffect.done, (state, { result: post }) => {
      const index = state.data.findIndex(({ id }) => id === post.id);
      const data = [...state.data];

      // TODO:: Убрать логику ниже (B2BCORE-7241).
      if (index >= 0) {
        data.splice(index, 1, post);
      }

      return { ...state, data };
    })
    .on(updateCommentsCountEvent, (state, { postId, postType, commentsCount }) => ({
      ...state,
      data: state.data.map((post) =>
        post.id === postId &&
        post.type === postType &&
        !isNewsPost(post) &&
        post.commentsCount !== commentsCount
          ? { ...post, commentsCount }
          : post,
      ),
    }))
    .on(switchFavoritePostEffect.done, (state, { params: { postId, favorite } }) => {
      if (shouldAddRemovePostsOnFavoriteChange && !favorite) {
        return {
          ...state,
          data: state.data.filter((post) => post.id !== postId),
        };
      }

      return {
        ...state,
        data: state.data.map((post) => (post.id === postId ? { ...post, favorite } : post)),
      };
    })
    .on(switchPinPostEffect.done, (state, { params: { postId, isPinned } }) => {
      if (storageParams?.baseParams?.pinnedOnly && !isPinned) {
        return {
          ...state,
          data: state.data.filter((post) => postId !== post.id),
        };
      }

      return {
        ...state,
        data: state.data.map((post) => (post.id === postId ? { ...post, isPinned } : post)),
      };
    })
    .on(updatePostsFavoriteEvent, (state, { updatedPost }) => {
      if (shouldAddRemovePostsOnFavoriteChange) {
        if (updatedPost.favorite) {
          return {
            ...state,
            data: [updatedPost, ...state.data],
          };
        }

        return {
          ...state,
          data: state.data.filter((post) => post.id !== updatedPost.id),
        };
      }

      return {
        ...state,
        data: state.data.map((post) =>
          post.id === updatedPost.id ? { ...post, favorite: updatedPost.favorite } : post,
        ),
      };
    })
    .on(resetAndRefetchEvent, () => {
      const params = storage.getLastRequestParams();

      storage.resetStoreEvent();
      storage.fetchEffect({ ...params, pageNumber: 1 });
    });

  return {
    storage,
    switchFavoritePostEffect,
    changePostStatusEffect,
    updateCommentsCountEvent,
    resetAndRefetchEvent,
  };
};

export type PostsStorage = ReturnType<typeof getPostsStorage>;

export const updatePostsSettingsEffect = createEffect<
  Partial<TimelineSettingsAllModel>,
  TimelineSettingsAllModel,
  AxiosError
>((params) => updatePostsSettings(params).then(({ data }) => data));

export const getPostsSettingsStorage = () => {
  const storage = abstractStorageFactory<TimelineSettingsAllModel, TimelineSettingsAllModel, null>({
    endpointBuilder: endpoints.timelines.settingsAll,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const resetPostsSettingsEffect = createEffect(resetPostsSettings);

  sample({
    clock: [resetPostsSettingsEffect.done],
    target: createEffect(storage.refetchWithLastParams),
  });

  return { storage, resetPostsSettingsEffect };
};
