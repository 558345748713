import { UiIcon, UiTypography } from '@vkph/ui';
import React from 'react';

import NotificationSVG from '@vkph/ui/svg/notification.svg';

import styles from './FeedError.scss';

export const FeedError = () => {
  return (
    <div className={styles.feedError}>
      <UiIcon component={NotificationSVG} width={48} height={48} className={styles.feedError__icon} />
      <UiTypography.Title level={4}>Не удалось загрузить данные</UiTypography.Title>
      <UiTypography.Text type="secondary" className={styles.feedError__description}>
        Попробуйте обновить страницу или обратитесь в службу поддержки
      </UiTypography.Text>
    </div>
  );
};
