import { UiTypography } from '@vkph/ui';
import React from 'react';

import styles from './DrawerTitle.scss';

type Props = {
  title: string;
};

export const DrawerTitle: React.FC<Props> = (props) => {
  const { title } = props;

  return (
    <div className={styles.drawerTitle}>
      <UiTypography.Title level={5} className={styles.drawerTitle__label}>
        {title}
      </UiTypography.Title>
    </div>
  );
};
