import {
  MultiSelectAvatarLabelDataType,
  UserAvatar,
  MultiSelectField,
  MultiSelectFieldProps,
} from '@vkph/components';
import { UiButton, UiForm, UiModal, UiModalProps, UiCell, notification } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getBlogMembersStorage, getSingleBlogStorage } from '@vkph/common/store/blogs';
import { fileStorageFileShareEffect } from '@vkph/common/store/filestorage';
import { useProfileOptionsListStorage } from '@vkph/common/store/profile/hooks';
import { BlogTypes } from '@vkph/common/types/blogs';
import { isFileStorageBlogContext } from '@vkph/common/types/guards';
import {
  BlogModel,
  BlogRestrictedType,
  FileStorageEntryInfoModel,
  UserIdModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage, getFullNameWithoutPatronymic } from '@vkph/common/utils';
import { OptionModel } from '@vkph/ui/types/option';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

type UserDataType = Omit<MultiSelectAvatarLabelDataType, 'isRejected'>;
type UserOption = OptionModel<UserIdModel, UserDataType>;

type FormValues = {
  users: UserOption[];
};

interface Props extends Pick<UiModalProps, 'onClose'> {
  fileInfo: FileStorageEntryInfoModel;
}

const getOption = ({ id, avatar, label }: { id: UserIdModel; avatar?: string; label: string }) => {
  return {
    value: id,
    data: {
      avatar,
      label: <UiCell avatar={<UserAvatar size="extraSmall" src={avatar} />} title={label} />,
      selectedLabel: label,
    },
  };
};

export const FileActionsShareModal: FC<Props> = (props) => {
  const { onClose, fileInfo } = props;
  const [form] = UiForm.useForm<FormValues>();
  const isFileStorageFileSharePending = useStore(fileStorageFileShareEffect.pending);

  const { options: profileListOptions, onUpdate } = useProfileOptionsListStorage({
    autoFetchParams: { isActive: true },
  });

  const blogId = useMemo(() => {
    return isFileStorageBlogContext(fileInfo?.context) ? fileInfo.context.blogId : 0;
  }, [fileInfo]);

  const blogMembersStorage = useMemo(() => getBlogMembersStorage(blogId), [blogId]);
  const singleBlogStorage = useMemo(
    () =>
      getSingleBlogStorage<BlogModel>({
        slugId: blogId,
        type: BlogTypes.BlogsView,
      }),
    [blogId],
  );

  const { data: blogMembersData } = useAbstractStorage(blogMembersStorage.storage, {
    autoFetchAndRefetch: Boolean(blogId),
  });
  const { data: singleBlogData } = useAbstractStorage(singleBlogStorage.storage, {
    autoFetchAndRefetch: Boolean(blogId),
  });

  const onFinish = ({ users }: FormValues) => {
    fileStorageFileShareEffect({
      fileId: fileInfo.id,
      targets: users.map((user) => user.value),
    })
      .then(() => {
        notification.success({ message: 'Вы успешно поделились файлом' });
        onClose?.();
      })
      .catch((e) =>
        notification.error({ message: getErrorResponseMessage(e, 'Не удалось поделиться файлом') }),
      );
  };

  const onSearchDebounced = useDebouncedCallback(onUpdate, 500);

  const userOptions = useMemo<UserOption[]>(() => {
    if (blogId && singleBlogData?.restrictedType !== BlogRestrictedType.Open) {
      return blogMembersData.map((member) => {
        const label = getFullNameWithoutPatronymic(member);

        return getOption({ id: member.keycloakId, avatar: member?.smallAvatar, label });
      });
    }

    return profileListOptions.map((option) => {
      return getOption({
        id: option.value,
        avatar: option.data?.smallAvatar,
        label: option.label,
      });
    });
  }, [profileListOptions, blogId, blogMembersData, singleBlogData, getOption]);

  const renderItemContent: MultiSelectFieldProps['ItemContent'] = ({ data: { label } }) => <>{label}</>;

  return (
    <UiForm form={form} requiredMark size="large" layout="vertical" onFinish={onFinish}>
      <UiModal.Header hasBottomBorder>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Поделиться файлом')} />
      </UiModal.Header>

      <UiModal.Content basePadding>
        <UiForm.Item label="Пользователь" required name="users">
          <MultiSelectField
            options={userOptions}
            ItemContent={renderItemContent}
            onSearch={onSearchDebounced}
          />
        </UiForm.Item>
      </UiModal.Content>

      <UiModal.Footer hasTopBorder>
        <UiModal.Footer.Buttons>
          <UiButton
            type="primary"
            onClick={form.submit}
            loading={isFileStorageFileSharePending}
            label="Поделиться"
          />
          <UiButton type="secondary" onClick={onClose} label="Отмена" />
        </UiModal.Footer.Buttons>
      </UiModal.Footer>
    </UiForm>
  );
};
