import { useTheme } from '@vkph/ui';
import type { UiCustomIconComponentProps } from '@vkph/ui';
import React, { FC, SVGProps } from 'react';

import FlameSvg from '@vkph/ui/svg/flame.svg';

interface NewsImportantIconProps
  extends Partial<Extract<UiCustomIconComponentProps, SVGProps<SVGSVGElement>>> {
  active?: boolean;
  showDefaultIcon?: boolean;
}

export const NewsImportantIcon: FC<NewsImportantIconProps> = (props) => {
  const { style, active = true, showDefaultIcon, ...restProps } = props;
  const [{ images: themeImages }] = useTheme();
  const src = themeImages?.importantNews;

  const params = { ...restProps, style: { filter: `grayscale(${active ? 0 : 100}%)`, ...style } };

  if (showDefaultIcon || !src) {
    return <FlameSvg {...params} />;
  }

  return <img {...params} src={src} alt="" />;
};
