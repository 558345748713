import { UiAvatar, UiTypography } from '@vkph/ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { SearchListItem } from '@vkph/common/types/models';
import { getRoutePath, RouteNames } from '@vkph/common/utils';

import styles from './SearchItemProfile.scss';

type Props = {
  profile: SearchListItem;
};

export const SearchItemProfile: React.FC<Props> = (props) => {
  const { profile } = props;

  return (
    <Link key={profile.id} to={getRoutePath(RouteNames.Profile, { id: profile.id })}>
      <div className={styles.searchItemProfile__result}>
        <UiAvatar size={40} src={profile.image} className={styles.searchItemProfile__avatar} />
        <div className={styles.searchItemProfile__description}>
          <UiTypography.Title level={5} bottom={0}>
            {profile.title}
          </UiTypography.Title>
          <UiTypography.Text type="secondary">{profile.subTitle}</UiTypography.Text>
          <UiTypography.Text type="secondary" className={styles.searchItemProfile__email}>
            {profile.email}
          </UiTypography.Text>
        </div>
      </div>
    </Link>
  );
};
