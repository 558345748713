import { UserAvatar, MultiSelectField } from '@vkph/components';
import {
  notification,
  Rule,
  UiCell,
  UiForm,
  UiIcon,
  UiList,
  UiSelect,
  UiSelectDefaultOption,
  UiSpinner,
  useModalBase,
  useTheme,
} from '@vkph/ui';
import { createEffect } from 'effector';
import isEqual from 'lodash/isEqual';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAbstractStorage } from '@vkph/common/hooks';
import {
  FileStorageStorageObjectEntryIdRoleParams,
  getFileStoragePermissionsRoleEntryIdStorage,
  getFileStoragePermissionsRolesStorage,
  setFileStorageStorageObjectEntryIdRolesEffect,
} from '@vkph/common/store';
import { useUsersGroupsOptionsListStorage } from '@vkph/common/store/groups/hooks';
import { useProfileOptionsListStorage } from '@vkph/common/store/profile/hooks';
import {
  BaseDataType,
  OptionModel,
  ServiceRolesGroupDataModel,
  UiOptionDataExtended,
} from '@vkph/common/types';
import {
  FileStorageEntryId,
  FileStorageEntryType,
  FileStorageFolderPermissionTypes,
  FileStoragePermissionsRoleName,
  FileStoragePermissionsRolesModel,
  UserModel,
  UserProfileJobModel,
} from '@vkph/common/types/models';
import { getErrorResponseMessage, getFullNameWithoutPatronymic } from '@vkph/common/utils';
import AboutSvg from '@vkph/ui/svg/about.svg';
import AvatarUsersSvg from '@vkph/ui/svg/avatar-users.svg';

export type FileListManagerPermissionFilestorageFormValues = {
  editorGroups: OptionModel[];
  editorPermissionType: FileStorageFolderPermissionTypes;
  editorUsers: OptionModel[];
  readerGroups: OptionModel[];
  readerPermissionType: FileStorageFolderPermissionTypes;
  readerUsers: OptionModel[];
};

const getPermissionType = (
  isOverride: boolean,
  list: (OptionUsersGroups | ServiceRolesGroupDataModel)[],
): FileStorageFolderPermissionTypes => {
  if (isOverride && !list.length) {
    return FileStorageFolderPermissionTypes.Nobody;
  }

  if (!isOverride && !list.length) {
    return FileStorageFolderPermissionTypes.Anyone;
  }

  return FileStorageFolderPermissionTypes.Some;
};

type Avatar = { avatar: string | ReactNode };
type OptionUsersGroups = OptionModel<string, BaseDataType & Avatar>;

const userRoleToOption = (user: UserModel): OptionUsersGroups => {
  const { firstName, lastName, keycloakId, avatar } = user;
  const currentFullName = getFullNameWithoutPatronymic({ firstName, lastName });

  return {
    value: keycloakId || '',
    data: {
      avatar: avatar || '',
      label: <UiCell avatar={<UserAvatar src={avatar} />} title={currentFullName} />,
      selectedLabel: currentFullName,
    },
  };
};

const groupRoleToOption = (group: ServiceRolesGroupDataModel): OptionUsersGroups => {
  return {
    value: group.id,
    data: {
      avatar: <AvatarUsersSvg />,
      label: (
        <UiCell avatar={<UiIcon component={AboutSvg} width={20} height={20} />} title={group.displayName} />
      ),
      selectedLabel: group.displayName,
    },
  };
};

const convertDataToForm = (
  data: FileStoragePermissionsRolesModel[],
): FileListManagerPermissionFilestorageFormValues => {
  const rolesMap = new Map(data.map((role) => [role.name, role]));
  const readers = rolesMap.get(FileStoragePermissionsRoleName.LocalSiteReader);
  const editors = rolesMap.get(FileStoragePermissionsRoleName.LocalSiteEditor);

  const readerGroups = (readers?.groups || []).map(groupRoleToOption);
  const readerUsers = (readers?.users || []).map(userRoleToOption);
  const readerPermissionType = getPermissionType(Boolean(readers?.isOverride), [
    ...readerGroups,
    ...readerUsers,
  ]);

  const editorGroups = (editors?.groups || []).map(groupRoleToOption);
  const editorUsers = (editors?.users || []).map(userRoleToOption);
  const editorPermissionType = getPermissionType(Boolean(editors?.isOverride), [
    ...editorGroups,
    ...editorUsers,
  ]);

  return {
    readerGroups,
    readerUsers,
    readerPermissionType,
    editorGroups,
    editorUsers,
    editorPermissionType,
  };
};

type OnSavePermissionFormParams = {
  showNotification: boolean;
  entryId?: FileStorageEntryId;
};
type OnSavePermissionFormState = {
  success: boolean;
};

const SAVE_PERMISSION_PARAMS_DEFAULT: OnSavePermissionFormParams = {
  showNotification: true,
  entryId: '',
};

const SAVE_PERMISSION_STATE_DEFAULT: OnSavePermissionFormState = {
  success: false,
};

export const onSavePermissionFormEffect = createEffect<
  OnSavePermissionFormParams,
  OnSavePermissionFormState
>();

type Props = {
  filestorageObjectId?: FileStorageEntryId;
  filestorageEntryType?: FileStorageEntryType;
  onClose?: () => void;
  open?: boolean;
  type?: 'file' | 'folder' | 'site';
  hintViewList: string[];
  hintEditList: string[];
};

export const FileListManagerPermissionFilestorageForm: FC<Props> = (props) => {
  const {
    onClose,
    filestorageObjectId,
    filestorageEntryType,
    type = 'folder',
    hintViewList,
    hintEditList,
  } = props;
  const { confirm } = useModalBase();
  const isEdit = Boolean(filestorageObjectId);
  const isSiteEdit = isEdit && type === 'site';
  const isFolder = filestorageEntryType === FileStorageEntryType.Folder;
  const permissionAlert =
    'В случае изменения доступа к родительскому объекту, выбранные пользователи и группы пользователей могут потерять доступ к этому объекту (если их исключили)';
  const [{ variables: themeVariables }] = useTheme();

  const defaultOptions = [
    { value: FileStorageFolderPermissionTypes.Some, label: 'Некоторые пользователи' },
    { value: FileStorageFolderPermissionTypes.Nobody, label: 'Никто из пользователей' },
  ];

  const permissionViewOptions = useMemo<UiSelectDefaultOption[]>(
    () => [
      {
        value: FileStorageFolderPermissionTypes.Anyone,
        label:
          type === 'site' ? 'Все пользователи сайта' : 'Все, кто может просматривать родительский объект',
      },
      ...defaultOptions,
    ],
    [type],
  );

  const permissionEditOptions = useMemo<UiSelectDefaultOption[]>(
    () => [
      {
        value: FileStorageFolderPermissionTypes.Anyone,
        label: type === 'site' ? 'Все пользователи сайта' : 'Все, кто может управлять родительским объектом',
      },
      ...defaultOptions,
    ],
    [type],
  );

  const { options: profileListOptions, onUpdate } = useProfileOptionsListStorage();
  const { options: usersGroupsOptions, onUpdate: onUpdateUsersGroups } = useUsersGroupsOptionsListStorage();

  const onSearchUserDebounced = useDebouncedCallback(onUpdate, 500);
  const onSearchUsersGroupsDebounced = useDebouncedCallback(onUpdateUsersGroups, 500);

  const fileStoragePermissionsRolesStorage = useMemo(getFileStoragePermissionsRolesStorage, []);
  const { data: fileStoragePermissionsRolesData, loading: isFileStoragePermissionsRolesLoading } =
    useAbstractStorage(fileStoragePermissionsRolesStorage.storage, {
      autoFetchAndRefetch: true,
      autoFetchParams: { isInstanceSpecific: true },
      cancelPendingRequestOnUnmount: true,
    });

  const fileStoragePermissionsRoleEntryIdStorage = useMemo(
    () => getFileStoragePermissionsRoleEntryIdStorage(filestorageObjectId || ''),
    [filestorageObjectId],
  );

  const { data: fileStoragePermissionsRoleEntryIdData, loading: isFileStoragePermissionsRoleEntryIdLoading } =
    useAbstractStorage(fileStoragePermissionsRoleEntryIdStorage.storage, {
      autoFetchAndRefetch: isEdit,
      cancelPendingRequestOnUnmount: true,
    });

  const form = UiForm.useFormInstance<FileListManagerPermissionFilestorageFormValues>();
  const [initialValues, setInitialValues] = useState<FileListManagerPermissionFilestorageFormValues>();
  const isLoading = isFileStoragePermissionsRoleEntryIdLoading || isFileStoragePermissionsRolesLoading;

  useEffect(() => {
    if (!isFileStoragePermissionsRoleEntryIdLoading) {
      const formValues = convertDataToForm(fileStoragePermissionsRoleEntryIdData);

      setInitialValues(formValues);
      form.setFieldsValue(formValues);
    }
  }, [fileStoragePermissionsRoleEntryIdData, isLoading]);

  const itemContent = ({ data }: UiOptionDataExtended<Partial<UserProfileJobModel> & BaseDataType>) => {
    const { avatar, selectedLabel } = data || {};
    const avatarProps = typeof avatar === 'string' ? { src: avatar } : { icon: avatar };

    return <UiCell title={selectedLabel} avatar={<UserAvatar size={24} {...avatarProps} />} />;
  };

  const userOptions = useMemo<Required<UiOptionDataExtended<BaseDataType>>[]>(() => {
    return profileListOptions.map((option) => ({
      value: option?.value,
      data: {
        avatar: option?.avatar,
        selectedLabel: option?.label,
        label: <UiCell title={option?.label} avatar={<UserAvatar size={24} src={option?.avatar} />} />,
      },
    }));
  }, [profileListOptions]);

  const groupsOptions = useMemo<Required<UiOptionDataExtended<BaseDataType>>[]>(() => {
    return usersGroupsOptions.map((group) => {
      return {
        value: group.value,
        data: {
          avatar: <AvatarUsersSvg />,
          selectedLabel: group.data?.displayName || '',
          label: <UiCell title={group?.name} avatar={<UserAvatar size={24} icon={<AvatarUsersSvg />} />} />,
        },
      };
    });
  }, [usersGroupsOptions]);

  const permissionReader = UiForm.useWatch('readerPermissionType', form);
  const permissionEditor = UiForm.useWatch('editorPermissionType', form);
  const isShowReader = FileStorageFolderPermissionTypes.Some === permissionReader;
  const isShowEditor = FileStorageFolderPermissionTypes.Some === permissionEditor;

  const onFinish = async (
    values: FileListManagerPermissionFilestorageFormValues,
    params?: OnSavePermissionFormParams,
  ): Promise<OnSavePermissionFormState> => {
    const { showNotification, entryId } = { ...SAVE_PERMISSION_PARAMS_DEFAULT, ...params };

    const rolesMap = new Map(fileStoragePermissionsRolesData.map((role) => [role.name, role.id]));
    const readerId = rolesMap.get(FileStoragePermissionsRoleName.LocalSiteReader);
    const editorId = rolesMap.get(FileStoragePermissionsRoleName.LocalSiteEditor);

    const isOverrideReader = values.readerPermissionType !== FileStorageFolderPermissionTypes.Anyone;
    const isOverrideEditor = values.editorPermissionType !== FileStorageFolderPermissionTypes.Anyone;

    const isSendListReader = values.readerPermissionType === FileStorageFolderPermissionTypes.Some;
    const isSendListEditor = values.editorPermissionType === FileStorageFolderPermissionTypes.Some;

    const readerList: FileStorageStorageObjectEntryIdRoleParams = {
      id: readerId || 0,
      groups: isSendListReader ? values.readerGroups.map(({ value }) => value) : [],
      users: isSendListReader ? values.readerUsers.map(({ value }) => value) : [],
      isOverride: isOverrideReader,
    };

    const editorList: FileStorageStorageObjectEntryIdRoleParams = {
      id: editorId || 0,
      groups: isSendListEditor ? values.editorGroups.map(({ value }) => value) : [],
      users: isSendListEditor ? values.editorUsers.map(({ value }) => value) : [],
      isOverride: isOverrideEditor,
    };

    const formState: OnSavePermissionFormState = SAVE_PERMISSION_STATE_DEFAULT;

    try {
      await setFileStorageStorageObjectEntryIdRolesEffect({
        entryId: entryId || filestorageObjectId || '',
        data: [readerList, editorList],
      });

      if (showNotification) {
        notification.success({
          message: 'Настройки доступа применены',
        });
      }

      onClose?.();

      formState.success = true;
    } catch (e) {
      notification.error({
        message: getErrorResponseMessage(e, 'Ошибка настройки доступа'),
      });

      formState.success = false;
    }

    return formState;
  };

  onSavePermissionFormEffect.use(async (params) => {
    await form.validateFields(['readerUsers', 'readerGroups', 'editorUsers', 'editorGroups'], {
      recursive: true,
    });

    const allValues = form.getFieldsValue(true);

    if (isEqual(allValues, initialValues)) {
      return { success: true };
    }

    const doConfirm = async () => {
      return confirm({
        title: 'Вы точно хотите изменить настройки доступов?',
        content:
          'Изменение настроек доступа повлияет на вложенные файлы и папки, если их настройки доступа наследуются от этого файлового хранилища',
        okText: 'Изменить',
        cancelText: 'Отмена',
        onOk: async () => {
          return onFinish(allValues, params);
        },
      });
    };

    const isConfirm = Boolean(await doConfirm());

    return { success: isConfirm };
  });

  const PermissionsHint: FC<{ data: string[] }> = (permissionHintProps) => {
    const { data } = permissionHintProps;

    return (
      <UiList
        dataSource={data}
        renderItem={(item) => (
          <UiList.Item
            style={{
              padding: 0,
              color: themeVariables.colorTextOnBrand,
              display: 'list-item',
              listStyle: 'inside',
            }}
          >
            {item}
          </UiList.Item>
        )}
      />
    );
  };

  const hintView = useMemo(() => {
    if (type === 'folder') {
      return `Кто может просматривать содержимое папки`;
    }

    if (type === 'file') {
      return `Кто может просматривать файл`;
    }

    return `Кто может просматривать содержимое файлового хранилища`;
  }, [type, isFolder]);

  const hintEdit = useMemo(() => {
    if (type === 'folder') {
      return `Кто может управлять содержимым папки`;
    }

    if (type === 'file') {
      return `Кто может управлять файлом`;
    }

    return `Кто может управлять содержимым файлового хранилища`;
  }, [type, isFolder]);

  const getPermissionFieldRule = (
    fieldName: keyof FileListManagerPermissionFilestorageFormValues,
    initial?: FileListManagerPermissionFilestorageFormValues,
  ): Rule => {
    return {
      validator: (_, value) => (initial?.[fieldName] === value ? Promise.resolve() : Promise.reject()),
      message: permissionAlert,
    } satisfies Rule;
  };

  return (
    <UiSpinner spinning={isLoading}>
      <UiForm form={form} size="large" layout="vertical" onFinish={onFinish} clearOnDestroy>
        <UiForm.Item
          name="readerPermissionType"
          label={hintView}
          tooltip={{ title: <PermissionsHint data={hintViewList} /> }}
          rules={isSiteEdit ? [getPermissionFieldRule('readerPermissionType', initialValues)] : []}
        >
          <UiSelect<FileStorageFolderPermissionTypes>
            options={permissionViewOptions}
            defaultValue={FileStorageFolderPermissionTypes.Anyone}
          />
        </UiForm.Item>
        {isShowReader && (
          <>
            <UiForm.Item name="readerGroups" label="Выбрать группу или группы пользователей">
              <MultiSelectField
                options={groupsOptions}
                ItemContent={itemContent}
                onSearch={onSearchUsersGroupsDebounced}
              />
            </UiForm.Item>
            <UiForm.Item name="readerUsers" label="Выбрать конкретных пользователей">
              <MultiSelectField
                options={userOptions}
                ItemContent={itemContent}
                onSearch={onSearchUserDebounced}
              />
            </UiForm.Item>
          </>
        )}
        <UiForm.Item
          name="editorPermissionType"
          label={hintEdit}
          tooltip={{ title: <PermissionsHint data={hintEditList} /> }}
          rules={isSiteEdit ? [getPermissionFieldRule('editorPermissionType', initialValues)] : []}
        >
          <UiSelect<FileStorageFolderPermissionTypes>
            options={permissionEditOptions}
            defaultValue={FileStorageFolderPermissionTypes.Anyone}
          />
        </UiForm.Item>
        {isShowEditor && (
          <>
            <UiForm.Item name="editorGroups" label="Выбрать группу или группы пользователей">
              <MultiSelectField
                options={groupsOptions}
                ItemContent={itemContent}
                onSearch={onSearchUsersGroupsDebounced}
              />
            </UiForm.Item>
            <UiForm.Item name="editorUsers" label="Выбрать конкретных пользователей">
              <MultiSelectField
                options={userOptions}
                ItemContent={itemContent}
                onSearch={onSearchUserDebounced}
              />
            </UiForm.Item>
          </>
        )}
      </UiForm>
    </UiSpinner>
  );
};
