import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { endpoints } from '@vkph/common/endpoints';
import { DictPaginated } from '@vkph/common/types/api';
import { FileStorageListEntryModel, FileStorageSlug, SearchParams } from '@vkph/common/types/models';
import { buildEndpointWithQueryParams } from '@vkph/common/utils';
import { abstractStorageFactory } from '@vkph/common/utils/effector';

export type SearchSitesFilesEntry = {
  name?: string;
  slug?: FileStorageSlug;
  entries: FileStorageListEntryModel[];
};

export const getSearchSitesFilesStorage = () => {
  const storage = abstractStorageFactory<
    DictPaginated<FileStorageListEntryModel>,
    SearchSitesFilesEntry[],
    [],
    SearchParams
  >({
    endpointBuilder: ({ filestorageRootFolderId, search, ...restParams }) =>
      buildEndpointWithQueryParams(
        endpoints.filestorage.folderEntryIdObjects(filestorageRootFolderId || ''),
        {
          search,
          withDescendants: true,
          siteFilestorage: true,
          excludeSiteFilestorage: true,
          ...restParams,
        },
      ),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: ({ items }) => {
      const grouped = groupBy(items, (item) => item?.siteFilestorage?.slug);

      return map(grouped, (entries) => ({
        slug: entries[0]?.siteFilestorage?.slug,
        name: entries[0]?.siteFilestorage?.name,
        entries,
      }));
    },
    paginationInfoRetriever: ({ meta }) => ({ count: meta.objectsTotal, page: meta.pageNumber }),
  });

  return { storage };
};
