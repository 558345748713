import { ActionsDropdown } from '@vkph/components';
import {
  BadgeColors,
  UiBadge,
  UiButton,
  UiIcon,
  UiIconProps,
  UiTag,
  UiTruncateMarkup,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import classNames from 'classnames';
import { isAfter } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { confirmEventParticipationEffect, declineEventParticipationEffect } from '@vkph/common/store/events';
import { BlogIconType } from '@vkph/common/types/blogs';
import { LongreadTypes } from '@vkph/common/types/longread';
import { EventModel } from '@vkph/common/types/models';
import {
  declension,
  getFormattedDateRange,
  guestsAccusativeDeclension,
  memberDeclension,
  seatsDeclension,
} from '@vkph/common/utils';
import { getBlogIcon } from '@vkph/components/utils/blogs/getBlogIcon';

import { EventImage } from '../../../event/event-image/EventImage';
import styles from './PostEvent.scss';

type Props = {
  event: EventModel;
  className?: string;
  onDelete?: () => void;
};

export const PostEvent: FC<Props> = (props) => {
  const { event, className, onDelete } = props;

  const {
    id,
    name,
    since,
    till,
    place,
    guestsCount,
    memberTotalUids,
    broadcastLink,
    seatsLeft,
    isUserParticipate,
    isMy: isOrganizer,
    fileStorageImageUrl,
    category,
  } = event;

  const { name: categoryName, extraInfo } = category || {};

  // todo подставить ссылку на  реальную картинку
  const imageUrl = fileStorageImageUrl ?? undefined;
  const participantsCount = memberTotalUids.length;
  const actualGuestsCount = guestsCount || 0;
  const guestsDeclensionText = `${declension(actualGuestsCount, guestsAccusativeDeclension)}`;
  const seatsLeftDeclensionText = `${declension(seatsLeft, seatsDeclension)} осталось`;
  const participantsDeclensionText = `${participantsCount} ${declension(
    participantsCount,
    memberDeclension,
  )}`;

  const isEventPass = useMemo(() => isAfter(new Date(), new Date(till)), [till]);
  const isShowSeatsLeft = seatsLeft > 0;
  const isShowGuestsCount = actualGuestsCount > 0;
  const isUserEventPass = isEventPass && !isOrganizer;

  const { lg: isLayoutLarge } = useBreakpoint();

  const linkProps = useLongreadLink({ type: LongreadTypes.Events, id: event.id });

  const toggleParticipateHandler: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (isUserParticipate) {
      declineEventParticipationEffect(id);
    } else {
      confirmEventParticipationEffect({ id, body: { invitedGuestsCount: 0 } });
    }
  };

  const seatsAndGuestsMeta = (
    <div className={styles.postEvent__metaSection}>
      {isShowSeatsLeft && (
        <div className={styles.postEvent__item}>
          <UiBadge color={BadgeColors.Negative} count={seatsLeft} className={styles.postEvent__icon} />
          <UiTypography.Text>{seatsLeftDeclensionText}</UiTypography.Text>
        </div>
      )}
      {isShowGuestsCount && (
        <div className={styles.postEvent__item}>
          <UiBadge count={actualGuestsCount} className={styles.postEvent__icon} />
          <UiTypography.Text>{guestsDeclensionText}</UiTypography.Text>
        </div>
      )}
    </div>
  );

  type GetMetaTagParams = {
    title: string;
    component: UiIconProps['component'];
    classname?: string;
  };

  const getMetaTag = ({ title, component, classname }: GetMetaTagParams) => (
    <div className={styles.postEvent__item}>
      <UiIcon
        component={component}
        height={20}
        width={20}
        className={classNames(styles.postEvent__icon, classname)}
      />
      <UiTypography.Text>{title}</UiTypography.Text>
    </div>
  );

  const participantsMeta = getMetaTag({
    title: participantsDeclensionText,
    component: getBlogIcon(BlogIconType.Community),
  });

  const organizerMeta = getMetaTag({
    title: 'Вы организатор',
    component: getBlogIcon(BlogIconType.UserChecked),
    classname: styles.postEvent__icon_white,
  });

  const acceptedMeta = getMetaTag({
    title: 'Вы приняли участие',
    component: getBlogIcon(BlogIconType.Success),
    classname: styles.postEvent__icon_success,
  });

  const passedMeta = getMetaTag({
    title: 'Мероприятие закончилось',
    component: getBlogIcon(BlogIconType.Time),
  });

  const participateButtonIcon = getBlogIcon(
    isUserParticipate ? BlogIconType.UserClear : BlogIconType.UserFollowing,
  );
  const participateButton = (
    <UiButton
      type={isUserParticipate ? 'tertiary-transparent' : 'primary'}
      size="large"
      label={isUserParticipate ? 'Отменить участие' : 'Участвовать'}
      onClick={toggleParticipateHandler}
      icon={<UiIcon component={participateButtonIcon} width={20} height={20} />}
      className={styles.postEvent__button}
    />
  );

  return (
    <Link className={classNames(styles.postEvent, className)} tabIndex={-1} {...linkProps}>
      <EventImage image={imageUrl} standalone>
        <div className={styles.postEvent__container}>
          <div
            className={classNames(styles.postEvent__header, {
              [styles.postEvent__header_small]: !isLayoutLarge,
            })}
          >
            <div className={styles.postEvent__item}>
              <div className={styles.postEvent__dateTime}>
                <div className={styles.postEvent__dateTimeItem}>
                  <UiIcon
                    component={getBlogIcon(BlogIconType.Calendar)}
                    height={20}
                    width={20}
                    className={styles.postEvent__icon}
                  />
                  <UiTypography.Title level={3}>
                    {getFormattedDateRange(since, till, 'date')}
                  </UiTypography.Title>
                </div>
                <div className={styles.postEvent__dateTimeItem}>
                  <UiIcon
                    component={getBlogIcon(BlogIconType.Time)}
                    height={20}
                    width={20}
                    className={styles.postEvent__icon}
                  />
                  <UiTypography.Title level={3}>
                    {getFormattedDateRange(since, till, 'time')}
                  </UiTypography.Title>
                </div>
              </div>
            </div>
            {broadcastLink && (
              <div className={styles.postEvent__item}>
                <UiIcon
                  component={getBlogIcon(BlogIconType.Video)}
                  height={20}
                  width={20}
                  className={styles.postEvent__icon}
                />
                <UiTypography.Text strong>Онлайн-трансляция</UiTypography.Text>
              </div>
            )}
            {Boolean(onDelete) && (
              <ActionsDropdown
                className={styles.postEvent__icon_white}
                items={[{ label: 'Удалить', onClick: onDelete }]}
              />
            )}
          </div>
          <div
            className={classNames(styles.postEvent__main, {
              [styles.postEvent__main_small]: !isLayoutLarge,
            })}
          >
            {category && <UiTag title={categoryName} color={extraInfo?.color} />}
            <div className={styles.postEvent__name}>
              <UiTruncateMarkup truncate lines={2}>
                {name}
              </UiTruncateMarkup>
            </div>
            {place && (
              <div className={styles.postEvent__geo}>
                <UiIcon
                  component={getBlogIcon(BlogIconType.Location)}
                  height={20}
                  width={20}
                  className={styles.postEvent__icon}
                />
                <UiTypography.Text strong>
                  <UiTruncateMarkup truncate>{place}</UiTruncateMarkup>
                </UiTypography.Text>
              </div>
            )}

            <div className={styles.postEvent__meta}>
              {!isEventPass && seatsAndGuestsMeta}
              <div className={styles.postEvent__metaSection}>
                {participantsMeta}
                {isOrganizer && organizerMeta}
                {!isOrganizer && !isEventPass && participateButton}
              </div>
              {isUserEventPass && isUserParticipate && acceptedMeta}
              {isUserEventPass && !isUserParticipate && passedMeta}
            </div>
          </div>
        </div>
      </EventImage>
    </Link>
  );
};
