import { Post, Reactions } from '@vkph/components';
import {
  UiCard,
  UiCol,
  UiFlex,
  UiIcon,
  UiRender,
  UiRenderType,
  UiRow,
  UiSpace,
  UiTag,
  UiTagTypes,
  UiTruncateMarkup,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import HTMLReactParser from 'html-react-parser';
import React, { FC, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { useLongreadLink } from '@vkph/common/hooks';
import { LongreadTypes } from '@vkph/common/types';
import { NewsArticle, NewsCategoriesDict, PostTypes } from '@vkph/common/types/models';
import { getActualEditorFormat, getLineBreakedTextFromHtml, getFormattedDate } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { variables, useTheme } from '@vkph/ui/providers/theme';
import PinSVG from '@vkph/ui/svg/pin.svg';

import { NewsImportantIcon } from '../important-icon';
import { NewsPostSkeleton } from './skeleton';

type Props = {
  newsCategoriesDist: NewsCategoriesDict | null;
  newsFeed: NewsArticle;
  linkProps?: LinkProps;
};

type NewsPostComposition = {
  Skeleton: typeof NewsPostSkeleton;
};

export const NewsPost: FC<Props> & NewsPostComposition = (props) => {
  const { newsCategoriesDist, newsFeed, linkProps } = props;
  const {
    id,
    type,
    categoryId,
    cover,
    title,
    pinned,
    publishedAt,
    important,
    body,
    viewsCount,
    tags,
    settings,
  } = newsFeed;
  const { isReacted, isComments } = settings;
  const [{ variables: themeVariables }] = useTheme();
  const breakpoints = useBreakpoint();

  const { spaceM, spaceXL, spaceS } = useSpace();
  const { to, state } = useLongreadLink({ type: LongreadTypes.News, id, newsType: type || PostTypes.Simple });

  const plainBodyText = useMemo(() => {
    const actualBodyFormat = getActualEditorFormat(body);

    return getLineBreakedTextFromHtml(actualBodyFormat.data);
  }, [body]);

  const reactions = isReacted ? newsFeed.reactions : undefined;
  const comments = isComments ? newsFeed.commentsCount : undefined;

  return (
    <Link tabIndex={-1} to={to} state={state} {...linkProps}>
      <UiCard size="default" styles={{ body: { padding: breakpoints.lg ? spaceXL : spaceM } }}>
        <UiCard.Content>
          <UiRow gutter={[spaceXL, spaceM]}>
            <UiCol span={24} lg={10}>
              <Post.Cover src={cover} height="100%" />
            </UiCol>

            <UiCol span={24} lg={14}>
              <UiFlex gap={spaceM} justify="space-between" vertical style={{ height: '100%' }}>
                <UiSpace size={spaceS} direction="vertical" full>
                  <UiRow gutter={spaceM}>
                    <UiCol flex={1}>
                      <UiSpace align="center" size={spaceS}>
                        {pinned && (
                          <UiIcon
                            component={PinSVG}
                            width={20}
                            height={20}
                            style={{ color: variables.themePalette.colorIcon }}
                          />
                        )}
                        {publishedAt && (
                          <UiTypography.Text strong type="secondary">
                            {getFormattedDate(publishedAt, 'dateTime')}
                          </UiTypography.Text>
                        )}
                        <UiTag
                          strong
                          size={spaceS}
                          shift={6}
                          title={categoryId ? newsCategoriesDist?.[categoryId]?.name : undefined}
                          color={categoryId ? newsCategoriesDist?.[categoryId]?.extraInfo?.color : undefined}
                          type={UiTagTypes.Dot}
                        />
                      </UiSpace>
                    </UiCol>

                    {important && (
                      <UiCol>
                        <UiIcon component={NewsImportantIcon} width={20} height={20} />
                      </UiCol>
                    )}
                  </UiRow>

                  <UiFlex vertical>
                    <UiTypography.Title level={3}>
                      <UiTruncateMarkup truncate lines={2}>
                        {title}
                      </UiTruncateMarkup>
                    </UiTypography.Title>
                    {plainBodyText && (
                      <UiTypography.Text type="secondary">
                        <UiTruncateMarkup truncate lines={4}>
                          {HTMLReactParser(plainBodyText)}
                        </UiTruncateMarkup>
                      </UiTypography.Text>
                    )}
                  </UiFlex>
                </UiSpace>
                <UiSpace size="middle" direction="vertical" full>
                  <UiRender
                    type={UiRenderType.NullElement}
                    visible={Boolean(reactions || comments || viewsCount)}
                  >
                    <Reactions reactions={reactions} comments={comments} views={viewsCount} stretch />
                  </UiRender>
                  <UiRender type={UiRenderType.NullElement} visible={tags.length > 0}>
                    <UiTag.Group tagProps={{ color: themeVariables.colorBrand16 }} tags={tags} />
                  </UiRender>
                </UiSpace>
              </UiFlex>
            </UiCol>
          </UiRow>
        </UiCard.Content>
      </UiCard>
    </Link>
  );
};

NewsPost.Skeleton = NewsPostSkeleton;
