import { UiAvatar, UiDivider, UiSpace, UiTruncateMarkup, UiTypography } from '@vkph/ui';
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';
import React from 'react';

import { NewsArticle, NewsCategoriesDict } from '@vkph/common/types/models';
import { useSpace } from '@vkph/ui/hooks';

type NewsListItemProps = {
  newsCategoriesDict: NewsCategoriesDict | null;
  newsFeed: NewsArticle;
};

export const NewsListItem: React.FC<NewsListItemProps> = (props) => {
  const { newsCategoriesDict, newsFeed } = props;
  const { spaceS } = useSpace();

  return (
    <UiSpace size={spaceS}>
      <UiAvatar src={newsFeed.cover} size={40} />
      <UiTypography.Paragraph>
        <UiTypography.Title level={5} bottom={0}>
          <UiTruncateMarkup truncate tooltipProps={{ title: newsFeed.title }}>
            {newsFeed.title}
          </UiTruncateMarkup>
        </UiTypography.Title>

        <UiTypography.Text type="secondary">
          <UiTruncateMarkup truncate>
            {format(new Date(newsFeed.publishedAt), 'dd MMMM, H:mm', { locale: ru })}
            <UiDivider.Dot type="secondary" />
            {(newsFeed.categoryId && newsCategoriesDict?.[newsFeed.categoryId]?.name) ?? 'Без категории'}
          </UiTruncateMarkup>
        </UiTypography.Text>
      </UiTypography.Paragraph>
    </UiSpace>
  );
};
