import { UserLinkCell, Reactions } from '@vkph/components';
import { UiCard, UiDescriptions, UiDescriptionsItemProps, UiEmpty, message, useBreakpoint } from '@vkph/ui';
import { useStore } from 'effector-react';
import React, { FC, useCallback } from 'react';

import { GetFileStorageEntryInfoStorage } from '@vkph/common/store/filestorage';
import { reactionEffect } from '@vkph/common/store/reactions';
import { EmojiUuid, FileStorageEntryId, ReactionContentType } from '@vkph/common/types/models';
import { getErrorResponseMessage, getFormattedDate } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { FileInfoCardGeneralLoading } from './loading/FileInfoCardGeneralLoading';

type Props = {
  fileInfoStorage: GetFileStorageEntryInfoStorage;
  id: FileStorageEntryId;
};

export const FileInfoCardGeneral: FC<Props> = (props) => {
  const { fileInfoStorage, id } = props;

  const { data: fileInfoData, error: fileInfoError } = useStore(fileInfoStorage.storage.store);
  const isFileInfoDataPending = useStore(fileInfoStorage.storage.fetchEffect.pending);
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceXL, space2XL } = useSpace();

  const descriptionItemProps: UiDescriptionsItemProps = {
    spaceProps: { direction: 'horizontal', size: 0 },
    labelProps: { strong: false, type: 'secondary' },
    labelRowProps: { align: 'middle', justify: 'start', style: { width: 140, height: 40 } },
    span: 24,
  };

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      return reactionEffect({
        emojiUuid,
        objectId: id,
        contentType: ReactionContentType.StorageObject,
      }).catch((e) =>
        message.error(
          getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
        ),
      );
    },
    [id],
  );

  const isDataFetched = !isFileInfoDataPending && fileInfoData;
  const isLoading = isFileInfoDataPending && !fileInfoData;
  const isEmpty = (!fileInfoData && !isLoading) || fileInfoError;

  return (
    <UiCard emptyPadding size="default" emptyRadius={!isLayoutLarge}>
      {isLayoutLarge && (
        <UiCard.Header>
          <UiCard.Header.Title>Общая информация</UiCard.Header.Title>
        </UiCard.Header>
      )}
      {isLoading && <FileInfoCardGeneralLoading />}
      {isDataFetched && (
        <>
          <UiCard.Content>
            <UiDescriptions padding={[0, isLayoutLarge ? spaceXL : 0]}>
              <UiDescriptions.Item {...descriptionItemProps} label="Количество версий">
                {fileInfoData.revisionsCount}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Дата создания">
                {getFormattedDate(fileInfoData.createdAt, 'dateTime')}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Автор">
                <UserLinkCell lines={1} size={space2XL} strong user={fileInfoData.createdBy} />
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Дата изменения">
                {getFormattedDate(fileInfoData.updatedAt, 'dateTime')}
              </UiDescriptions.Item>
              <UiDescriptions.Item {...descriptionItemProps} label="Автор изменения">
                <UserLinkCell lines={1} size={space2XL} strong user={fileInfoData.updatedBy} />
              </UiDescriptions.Item>
            </UiDescriptions>
          </UiCard.Content>
          <UiCard.Footer style={{ display: 'block' }}>
            <Reactions
              onReaction={onReaction}
              reactions={fileInfoData?.reactions}
              views={fileInfoData?.objectViewsCount}
              stretch
            />
          </UiCard.Footer>
        </>
      )}
      {isEmpty && <UiEmpty.Feed emptyMessage={{ header: 'Ошибка получения общей информации' }} />}
    </UiCard>
  );
};
