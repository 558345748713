import { UiButton, UiIcon, UiInput, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { SearchCategoryType, SearchParams } from '@vkph/common/types/models';
import { createArrayMock } from '@vkph/common/utils';
import ClearSVG from '@vkph/ui/svg/clear.svg';

import { getSearchProfileStorage } from '../../../store/search/search-profile';
import { SearchFilter } from '../SearchPage';
import { SearchItemAdapter } from '../search-item/SearchItemAdapter';
import styles from './SearchContent.scss';
import { SearchContentLoading } from './loading/SearchContentLoading';

type Props = {
  selectedSearchFilters: SearchFilter[];
  deleteFilter: (selectedSearchFilter: SearchFilter) => void;
  deleteAllFilters: () => void;
  origin: SearchCategoryType;
  minSymbolsToSearch: number;
  fetchParams: SearchParams;
};

const searchProfileStorage = getSearchProfileStorage();
const MOCKS_LENGTH = 3;

const searchResultMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <SearchContentLoading key={key} />);

export const SearchContent: FC<Props> = (props) => {
  const { selectedSearchFilters, deleteFilter, deleteAllFilters, origin, minSymbolsToSearch, fetchParams } =
    props;

  const {
    data: foundUsers,
    loading,
    fetchFx: fetchUserSearch,
  } = useAbstractStorage(searchProfileStorage.storage, {});

  const onSearch = (search: string) => {
    if (search.length >= minSymbolsToSearch) {
      fetchUserSearch({ ...fetchParams, search });
    }
  };

  return (
    <div className={styles.searchContent}>
      <UiInput.SearchBox
        allowClear
        size="large"
        placeholder="Поиск"
        enterButton="Найти"
        className={styles.searchContent__input}
        onSearch={onSearch}
      />
      <div className={styles.searchContent__filters}>
        {selectedSearchFilters.map((filter) => (
          <div key={filter.label} className={styles.searchContent__filter}>
            <UiTypography.Text>{`${filter.label}: `}</UiTypography.Text>
            <UiTypography.Text strong>{filter.value}</UiTypography.Text>
            <UiIcon
              component={ClearSVG}
              height={20}
              width={20}
              onClick={() => deleteFilter({ label: filter.label })}
              className={styles.searchContent__icon}
            />
          </div>
        ))}
      </div>
      <div className={styles.searchContent__searchResultsHeader}>
        <div>
          <UiTypography.Text>Найдено: </UiTypography.Text>
          <UiTypography.Text strong>{foundUsers.length}</UiTypography.Text>
        </div>
        <UiButton
          type="link"
          icon={<ClearSVG />}
          label="Очистить фильтры"
          onClick={() => deleteAllFilters()}
        />
      </div>
      {loading && searchResultMocks}
      {!loading && foundUsers.map((item) => <SearchItemAdapter item={item} origin={origin} key={item.id} />)}
    </div>
  );
};
