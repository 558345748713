import { Post, Reactions, RouterLink } from '@vkph/components';
import {
  UiButton,
  UiDivider,
  UiRender,
  UiRenderType,
  UiSpace,
  UiTypography,
  message,
  useBreakpoint,
} from '@vkph/ui';
import React, { CSSProperties, FC, useCallback, useContext } from 'react';

import { reactionEffect } from '@vkph/common/store/reactions';
import {
  CommentContentTypes,
  EmojiUuid,
  ReactionContentType,
  TimelineRecordTargetModel,
  TimelineUserStructureContentModel,
} from '@vkph/common/types/models';
import { RouteNames, getErrorResponseMessage, getRoutePath } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';

import { PostCompound } from '../../post/compound/PostCompound';
import { TimelineCardProps } from '../TimelineCard';
import { TimelineCardGutterContext } from '../context';

const linkStyle: CSSProperties = { fontSize: 'inherit', lineHeight: 'inherit', fontWeight: 'inherit' };

interface Props extends Pick<TimelineCardProps, 'onClick'> {
  record: TimelineRecordTargetModel<TimelineUserStructureContentModel>;
}

export const TimelineCardUserStructure: FC<Props> = (props) => {
  const { record, onClick } = props;
  const [verticalGutter, horizontalGutter] = useContext(TimelineCardGutterContext);
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();

  const { content, target, createdAt, publishedAt, id } = record;
  const { oldPosition, position, oldGroup, group, reactions, commentsCount } = content;
  const actionWord = !oldGroup && !oldPosition ? 'Принят(а)' : 'Переведен(а)';

  const onReaction = useCallback(
    (emojiUuid?: EmojiUuid) => {
      reactionEffect({
        emojiUuid,
        objectId: id,
        contentType: ReactionContentType.TimelineRecord,
      }).catch((e) =>
        message.error(
          getErrorResponseMessage(e, `Не удалось ${emojiUuid ? 'поставить' : 'удалить'} реакцию`),
        ),
      );
    },
    [id],
  );

  return (
    <Post style={{ paddingTop: verticalGutter }}>
      <Post.Header padding={[0, horizontalGutter]} author={target} postDate={publishedAt || createdAt} />
      <Post.Body padding={[spaceM, horizontalGutter]}>
        <UiSpace direction="vertical" size={spaceXL}>
          <UiButton.Decorator onClick={() => onClick?.(record)}>
            <UiTypography.Title level={4}>
              {actionWord}
              {group && (
                <>
                  <> в </>
                  <RouterLink style={linkStyle} to={getRoutePath(RouteNames.Structure, { id: group.id })}>
                    {group.name}
                  </RouterLink>
                </>
              )}
              {position && (
                <>
                  <> на должность </>
                  <RouterLink style={linkStyle} to={getRoutePath(RouteNames.AdvancedSearch)}>
                    {position.name}
                  </RouterLink>
                </>
              )}
            </UiTypography.Title>
          </UiButton.Decorator>
          <Reactions reactions={reactions} comments={commentsCount} onReaction={onReaction} />
        </UiSpace>
      </Post.Body>
      <UiRender type={UiRenderType.NullElement} visible={isLayoutLarge}>
        <UiDivider emptyMargin />
        <Post.Footer>
          <PostCompound.CommentsWithInput
            objectId={record.id}
            contentType={CommentContentTypes.TimelineRecord}
            showInput
          />
        </Post.Footer>
      </UiRender>
    </Post>
  );
};
