import { Post } from '@vkph/components';
import {
  UiIcon,
  UiSpace,
  UiTag,
  UiTooltipPlacement,
  UiTruncateMarkupProps,
  UiTypography,
  useBreakpoint,
} from '@vkph/ui';
import React, { FC, useMemo } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getPostAttachmentsStorage } from '@vkph/common/store/attachment';
import { PostBasicModel } from '@vkph/common/types/models';
import { getFormattedDate, getPlainTextFromHtml, parseTextPostContent } from '@vkph/common/utils';
import { usePaddingStyle, useSpace } from '@vkph/ui/hooks';
import AttachSvg from '@vkph/ui/svg/attach.svg';

import { PostCompoundSearchViewContent } from './content/PostCompoundSearchViewContent';

export type PostCompoundSearchViewProps = {
  post: PostBasicModel;
  compact?: boolean;
  contentLines?: UiTruncateMarkupProps['lines'];
  tooltipPlacement?: UiTooltipPlacement;
};

export const PostCompoundSearchView: FC<PostCompoundSearchViewProps> = (props) => {
  const { post, compact, contentLines, tooltipPlacement } = props;
  const { id, type, tags, title, publishedAt, createdAt } = post;
  const { lg: isLayoutLarge } = useBreakpoint();
  const { spaceM, spaceXL } = useSpace();
  const viewPadding = usePaddingStyle([0, spaceXL]);

  const plainBodyText = useMemo(() => {
    const parsedHtml = parseTextPostContent(post);

    return getPlainTextFromHtml(parsedHtml);
  }, [post]);

  const { storage: attachmentsStorage } = useMemo(() => {
    return getPostAttachmentsStorage({
      postId: id,
      postType: type,
    });
  }, [id, type]);

  const { data: postAttachments } = useAbstractStorage(attachmentsStorage, {
    autoFetchAndRefetch: !compact,
    cancelPendingRequestOnUnmount: true,
  });

  const publishDate = useMemo(() => {
    return getFormattedDate(publishedAt || createdAt, 'dateTime');
  }, [publishedAt, createdAt]);

  const postInfo = (
    <UiSpace size={0} full direction="vertical">
      {title && <UiTypography.Title level={4}>{title}</UiTypography.Title>}
      {plainBodyText && <PostCompoundSearchViewContent lines={contentLines} text={plainBodyText} />}
    </UiSpace>
  );

  const postAttachmentsInfo = (
    <UiIcon.Label component={AttachSvg} label={`Вложения: ${postAttachments.length}`} strong />
  );

  const PostSearchViewSubtitle: FC = () => {
    if (compact) {
      return (
        <UiSpace size={0} direction="vertical" align="start">
          {publishDate}
          {plainBodyText && (
            <PostCompoundSearchViewContent
              lines={contentLines}
              type="secondary"
              compact
              text={plainBodyText}
              tooltipPlacement={tooltipPlacement}
            />
          )}
        </UiSpace>
      );
    }

    return (
      <UiSpace size={spaceM} full direction="vertical">
        <UiSpace size={0} full direction="vertical">
          <UiSpace full direction="vertical">
            {publishDate}
            {postInfo}
          </UiSpace>
        </UiSpace>
        {Boolean(tags.length) && <UiTag.Group tags={tags} />}
        {Boolean(postAttachments.length) && postAttachmentsInfo}
      </UiSpace>
    );
  };

  return (
    <UiSpace direction="vertical" full style={viewPadding}>
      <Post.Header
        spaceProps={{ align: 'start' }}
        author={post.author}
        postDate={post.publishedAt || post.createdAt}
        subTitle={isLayoutLarge && <PostSearchViewSubtitle />}
      />
      {!isLayoutLarge && (
        <UiSpace full direction="vertical">
          {postInfo}
          {Boolean(postAttachments.length) && postAttachmentsInfo}
        </UiSpace>
      )}
    </UiSpace>
  );
};
