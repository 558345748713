import differenceInMinutes from 'date-fns/differenceInMinutes';
import React, { FC, useEffect, useState } from 'react';

import styles from './ProfileWeekTimeline.scss';

const convertMinutesToPercent = (minutes: number, hourStart: number, hourEnd: number) => {
  return `${Math.round((minutes / 60 / (hourEnd - hourStart)) * 1000) / 10}%`;
};

type Props = {
  hourStart: number;
  hourEnd: number;
};

export const ProfileWeekTimeline: FC<Props> = (props) => {
  const { hourStart, hourEnd } = props;
  const [dateNow, setDateNow] = useState(new Date());

  const tick = () => setDateNow(() => new Date());

  useEffect(() => {
    const timerInterval = setInterval(tick, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  const timeStart = new Date();

  timeStart.setHours(hourStart);
  timeStart.setMinutes(0);

  const maxMinutes = (hourEnd - hourStart) * 60;
  const diffMinutes = differenceInMinutes(dateNow, timeStart);
  const minutes = diffMinutes < 0 ? 0 : Math.min(diffMinutes, maxMinutes);

  const getPercentPosition = () => {
    return convertMinutesToPercent(minutes, hourStart, hourEnd);
  };

  return (
    <div className={styles.profileWeekTimeline} style={{ left: getPercentPosition() }}>
      <div className={styles.profileWeekTimeline__line} />
    </div>
  );
};
