import React, { FC, useMemo } from 'react';

import { useAbstractStorage, useQueryParams } from '@vkph/common/hooks';
import { getFileStorageFolderCategoriesStorage } from '@vkph/common/store/filestorage';
import { FileStorageEntryId } from '@vkph/common/types/models';
import { UiCard, UiMenu, UiMenuItemType, UiSelectInfo, UiTypography, useSpace } from '@vkph/ui';

type Props = {
  entryId: FileStorageEntryId;
  selectedCategoryIds?: string;
};

export const CATEGORIES_ALL_KEY = 'CATEGORIES_ALL_KEY';

const CATEGORIES_ALL = { label: 'Все', key: CATEGORIES_ALL_KEY } satisfies UiMenuItemType;

export const FileStorageCategoryMenu: FC<Props> = (props) => {
  const { entryId } = props;
  const { queryParams, setQueryParams } = useQueryParams<{
    categories?: string;
  }>();
  const { spaceL } = useSpace();
  const { categories: queryCategories } = queryParams;
  const selectedCategories = queryCategories?.split(',');
  const activeCategories =
    selectedCategories && selectedCategories.length > 0 ? selectedCategories : [CATEGORIES_ALL_KEY];

  const fileStorageCategoriesStorage = useMemo(getFileStorageFolderCategoriesStorage, []);

  const { data: fileStorageCategoryData } = useAbstractStorage(fileStorageCategoriesStorage.storage, {
    autoFetchParams: { entryId },
    autoFetchAndRefetch: Boolean(entryId),
    cancelPendingRequestOnUnmount: true,
  });

  const categoriesMenuItems = useMemo<UiMenuItemType[]>(() => {
    return [
      CATEGORIES_ALL,
      ...fileStorageCategoryData.map((entry) => ({
        key: String(entry.id),
        label: entry.name,
      })),
    ];
  }, [fileStorageCategoryData]);

  const onSelect = ({ key, selectedKeys }: UiSelectInfo) => {
    const isCategoryKey = key !== CATEGORIES_ALL_KEY && selectedKeys.length;
    const onlyCategories = selectedKeys.filter((item) => item !== CATEGORIES_ALL_KEY);

    setQueryParams({ ...queryParams, categories: isCategoryKey ? onlyCategories.join(',') : '' });
  };

  const onDeselect = ({ key, selectedKeys }: UiSelectInfo) => {
    if (key === CATEGORIES_ALL_KEY) {
      return;
    }

    const newSelectedKeys = selectedKeys.filter((item) => item !== key);

    const newSelectedCategories = {
      ...queryParams,
      categories: newSelectedKeys.length > 0 ? newSelectedKeys.join(',') : '',
    };

    setQueryParams(newSelectedCategories);
  };

  return (
    <UiCard size="default" emptyPadding>
      <UiCard.Header>
        <UiTypography.Title level={4} bottom={0}>
          Категории
        </UiTypography.Title>
      </UiCard.Header>
      <UiCard.Content>
        <UiMenu
          multiple
          style={{ marginBottom: spaceL }}
          items={categoriesMenuItems}
          onSelect={onSelect}
          onDeselect={onDeselect}
          defaultSelectedKeys={[String(categoriesMenuItems[0].key)]}
          selectedKeys={activeCategories}
        />
      </UiCard.Content>
    </UiCard>
  );
};
